export interface EmailModelImpl {
  _id: string;
  email_send_at: Date;
  email_error: string;
  email_state: 'not-send' | 'send' | 'error';
  email_type: string;
  request_id: string;
  user_id: string;
  created_at: Date;
  updated_at: Date;
}

export class EmailModelDTO implements EmailModelImpl {
  _id!: string;
  email_send_at!: Date;
  email_error!: string;
  email_state!: 'not-send' | 'send' | 'error';
  email_type!: string;
  request_id!: string;
  user_id!: string;
  created_at!: Date;
  updated_at!: Date;
}

export class EmailModel extends EmailModelDTO {
  constructor(dto: Partial<EmailModelDTO>) {
    super();
    Object.assign(this, dto);
  }
}
