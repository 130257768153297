import { EmailModel, EmailModelDTO } from '@/models/Emails.model';
import { RequestModel, RequestModelDTO } from '@/models/Request.model';
import API from '../repository';

const RESOURCE = '/requests';

export interface ResponseListRequests {
  result: RequestModelDTO[];
  total_results: number;
  total_pages: number;
  page: number;
  limit: number;
}

export default {
  async getListRequests({ query, page = 1, limit = 10 }: { query?: {} | null; page: number; limit: number }) {
    const result: ResponseListRequests = await API.get(`${RESOURCE}/list`, {
      params: {
        page,
        limit,
        ...query,
      },
    });
    return {
      requests: result.result.map(c => new RequestModel(c)),
      total_results: result.total_results,
      total_pages: result.total_pages,
      page: result.page,
      limit: result.limit,
    };
  },

  getRequestComplete: async (request_id: string) => {
    const { result }: { result: RequestModelDTO } = await API.get(`${RESOURCE}/${request_id}/all-data`);
    return new RequestModel(result);
  },

  updateRegistroID: async (request_id: string, registro_id: string) => {
    const { result }: { result: RequestModelDTO } = await API.put(`${RESOURCE}/${request_id}/add-registro-id`, {
      registro_id,
    });
    return new RequestModel(result);
  },
  updateNote: async (request_id: string, note: string) => {
    const { result }: { result: RequestModelDTO } = await API.put(`${RESOURCE}/${request_id}/add-notes`, {
      note,
    });
    return new RequestModel(result);
  },

  updateStateToFinish: async (request_id: string) => {
    const { result }: { result: RequestModelDTO } = await API.put(`${RESOURCE}/${request_id}/finished`);
    return new RequestModel(result);
  },

  async notificationRequestInitProcess(request_id: string): Promise<any> {
    return await API.post(`${RESOURCE}/${request_id}/notification/init-process`);
  },
  async notificationRequestDeclinedProcess(request_id: string): Promise<any> {
    return await API.post(`${RESOURCE}/${request_id}/notification/declined-request`);
  },
  async notificationRequestFondosProcess(request_id: string): Promise<any> {
    return await API.post(`${RESOURCE}/${request_id}/notification/fondos-request`);
  },

  async notificationSendFile(request_id: string, formData: FormData): Promise<any> {
    return await API.post(`${RESOURCE}/${request_id}/notification/send-file`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },

  async createNotaSimpleInRegistroPropiedades(request_id: string, registro_id: string) {
    const result: { payment_status: 'succeeded' | 'error' } = await API.post(
      `${RESOURCE}/${request_id}/process-nota-simple`,
      {
        nota_simple_registro_id: registro_id,
      },
    );
    return result;
  },

  async findDataRequestRelated(request_id: string) {
    const requests: RequestModelDTO[] = await API.get(`${RESOURCE}/${request_id}/requests-related`);
    return requests.map(r => new RequestModel(r));
  },

  async findEmailsRequestRelated(request_id: string) {
    const emails: EmailModelDTO[] = await API.get(`${RESOURCE}/${request_id}/emails-related`);
    return emails.map(r => new EmailModel(r));
  },
};
