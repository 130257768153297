export interface RequestModelImpl {
  _id: string;
  payments_intents?: any[];
  request_id_registro?: string;
  request_extra_data?: string;
  request_data: string;
  request_state: string;
  request_type: string;
  payment_id?: string;
  user_id: string;
  user_email?: string;
  created_at: Date;
  updated_at: Date;
}

export class RequestModelDTO implements RequestModelImpl {
  _id = '';
  payments_intents = [];
  request_id_registro? = '';
  request_extra_data? = '';
  request_data = '';
  request_state = '';
  request_type = '';
  payment_id? = '';
  user_id = '';
  user_email?: string = '';
  created_at = new Date();
  updated_at = new Date();
}

export class RequestModel extends RequestModelDTO {
  constructor(dto: Partial<RequestModelDTO>) {
    super();
    Object.assign(this, dto);
  }
}
